import React from 'react';

const ImageWithFallback = ({ image, dim, alt, fallbackSrc }) => {
  const handleError = (event) => {
    event.target.onerror = null; // Evita loop infinito se il fallback fallisce
    event.target.src = fallbackSrc;
  };

  return <img key={image.id}
              src={image.src}
              alt={alt}
              onError={handleError}
              style={{
                position: 'absolute',
                left: image.position.x,
                top: image.position.y,
                //transform: `rotate(${Math.floor(Math.random() * (90 + 1))}deg)`,
                width: `${dim ?? 50}px`,
                height: `${dim ?? 50}px`,
              }}/>;
}

export default ImageWithFallback;