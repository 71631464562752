import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import QueueElement from "../components/QueueElement";
import {
  Backdrop,
  Box,
  Button, Chip,
  CircularProgress,
  CssBaseline,
  Dialog,
  Divider,
  Grid,
  IconButton,
  Menu,
  Stack,
  ThemeProvider,
  Typography
} from "@mui/material";
import AddQueueElement from "../components/AddQueueElement";
import {firestore} from "../firebase/clientApp";
import {Code, Download, OpenInNew, PlayArrow, Settings, Stop, Visibility} from "@mui/icons-material";
import {PagesButtons} from "../components/EventDetail/PagesButtons";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {EventSettings} from "../components/EventDetail/EventSettings";
import {pleshDark} from "../theme/pleshDark";
import QrButton from "../components/QrButton";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {reorderList} from "../functions/reorderList";
import {Main} from "../layouts/main";
import axios from "axios";
import {API_URL} from "../config";
import Tooltip from "@mui/material/Tooltip";
import CustomTimerSettings from "../components/Control/CustomTimerSettings";
import CountdownTimerWrapper from "../components/Control/CountdownTimer";

export default function EventDetail() {
  const {id} = useParams()
  const url = 'users/plesh/events/' + id
  const [queue, queueLoading, queueError] = useCollection(firestore.collection(url + '/queue'), {})
  const [reorderedQueue, setReorderedQueue] = useState([])
  const [pinnedQueue, setPinnedQueue] = useState([])
  const [event, eventLoading, eventError] = useDocument(firestore.doc('users/plesh/events/' + id))
  const [settingsOpened, setSettingsOpened] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [wait, setWait] = useState(false)
  const [documentazione, setDocumentazione] = useState(false)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (queue && !queueLoading && !queueError) {
      if (queue.docs.length > 0) {
        let queueDocs = reorderList(queue, queue.docs[0].data().position ? 'position' : "timestamp")
        let ordered = queueDocs.filter((q) => !q.pinned)
        let pinned = queueDocs.filter((q) => q.pinned)
        setReorderedQueue([...ordered])
        setPinnedQueue([...pinned])
      }
    }
  }, [queue, queueLoading, queueError])

  const handleMenu = (e) => {
    setAnchorEl(e.currentTarget)
  }

  const openSettings = () => {
    setSettingsOpened(true)
  }

  const closeSettings = () => {
    setSettingsOpened(false)
  }

  const handleDocumentazione = () => {
    setDocumentazione(!documentazione)
  }

  function playPauseEvent() {
    firestore
      .collection('users/plesh/events')
      .doc(id)
      .update({
        playing: !event.data().playing,
        elementPlaying: ''
      }).then()
  }

  async function handleAddElement(newElement) {
    await firestore
      .collection(url + '/queue')
      .add(newElement);
  }

  async function updatePosition(dati) {
    await dati.forEach((d, i) => {
      firestore
        .collection('users/plesh/events/' + id + '/queue')
        .doc(d.id)
        .update({
          position: i + 1
        }, {merge: true}).then();
    })
  }

  const onDragEnd = async (e) => {
    setWait(true)
    if (!e.destination) {
      setWait(false)
      return
    }
    let tempData = reorderedQueue;
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    await updatePosition(tempData)
    setWait(false)
  }

  async function handlePlayElement(i) {
    firestore
      .collection('users/plesh/events')
      .doc(id)
      .set({elementPlaying: i === event.data().elementPlaying ? '' : i},
        {merge: true})
      .then()
  }

  async function pinElement(i) {
    let docRef = firestore
      .collection('users/plesh/events/' + id + '/queue')
      .doc(i)
    let doc = await docRef.get()
    let data = doc.data()
    await docRef
      .set({pinned: data.pinned ? !data.pinned : true}, {merge: true})
      .then()
  }

  function handleRemoveElement(elementToRemove) {
    firestore
      .collection(url + '/queue')
      .doc(elementToRemove)
      .delete()
      .then()
  }

  function Queue({reorderedQueue, elementPlaying, playing}) {
    return (
      reorderedQueue.map((q, index) => (
          <Stack direction={'row'} alignItems={'center'}>
            {(!q.pinned) &&
              <Box pr={1}>
                <Chip label={index + 1}/>
              </Box>}
            <QueueElement playing={playing} elementPlaying={elementPlaying} element={q}
                          handleRemoveElement={handleRemoveElement}
                          pinElement={pinElement}
                          handlePlayElement={handlePlayElement} key={index} index={index}/>
          </Stack>
        )
      )
    )
  }

  const QueueCol = ({queue}) => (
    <Box sx={{position: 'relative', zIndex: 0}}>
      <Backdrop sx={{position: 'absolute', zIndex: 99}} open={wait}>
        <CircularProgress color="inherit"/>
      </Backdrop>
      <DragDropContext onDragEnd={onDragEnd}>
        <Stack spacing={1}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {
                  (queue && !queueLoading && !queueError && event && !eventError && !eventLoading) &&
                  <Queue playing={event.data().playing} reorderedQueue={queue}
                         elementPlaying={event.data().elementPlaying}/>
                }
              </Box>
            )}
          </Droppable>
        </Stack>
      </DragDropContext>
    </Box>
  )

  return (
    <Main>
      <Box sx={{px: 2}}>
        <Backdrop //Backdrop to use for any api request (now used for download report)
          sx={{color: '#fff', zIndex: 99, position: 'absolute'}}
          open={loading}
        >
          <CircularProgress color="inherit"/>
        </Backdrop>
        {(event && !eventError && !eventLoading) &&
          <ThemeProvider theme={pleshDark}>
            <CssBaseline/>
            <Box>
              <Dialog onClose={closeSettings} open={settingsOpened} scroll={"body"}
                      PaperProps={{variant: 'gradient'}} maxWidth={"lg"} fullWidth>
                <EventSettings event={event} closeSettings={closeSettings}/>
              </Dialog>
              <Stack sx={{py: 1}} direction={{xs: "column", md: "row"}} justifyContent={"space-between"}>
                <Typography style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                            variant={'h3'}>{event.data().title}</Typography>
                <Stack direction={'row'} alignItems={"center"}>
                  <Box>
                    <Tooltip title="Download event report">
                      <IconButton onClick={() => downloadReport(setLoading, event.id)} size={'large'}>
                        <Download fontSize={'inherit'}/>
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Box>
                    <QrButton event={event}/>
                  </Box>
                  <Box>
                    <IconButton size={'large'} onClick={openSettings}>
                      <Settings fontSize={"inherit"}/>
                    </IconButton>
                  </Box>
                  <Box>
                    <IconButton onClick={playPauseEvent} size={'large'}>
                      {event.data().playing ? <Stop fontSize={"inherit"}/> :
                        <PlayArrow fontSize={"inherit"}/>}
                    </IconButton>
                  </Box>
                </Stack>
              </Stack>
              <Divider/>
              <Stack direction={{xs: 'column', md: 'row'}} alignItems={'center'}
                     justifyContent={'space-between'} spacing={4} sx={{py: 1}}>
                <Stack direction={{xs: 'column', md: 'row'}} alignItems={'center'} spacing={4}>
                  <Typography variant={'h4'}>Queue</Typography>
                  <Box>
                    <AddQueueElement handleAddElement={handleAddElement}
                                     reorderedQueue={reorderedQueue}/>
                  </Box>
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <CustomTimerSettings eventTimer={event?.data().timer}/>
                    <CountdownTimerWrapper label={event?.data()?.timer?.label}
                                           initialTime={event?.data()?.timer?.duration}
                                           isLive={!!event?.data()?.timer?.startedAt}
                                           startedAt={event?.data()?.timer?.startedAt}/>
                    <Tooltip title={"Timer Viewer"} children={
                      <IconButton size={'small'} href={`/control/${id}/timer`} target={'_blank'}>
                        <OpenInNew fontSize={'small'}/>
                      </IconButton>
                    }/>
                  </Stack>
                </Stack>
                <Stack direction={'row'} spacing={2} onClick={handleDocumentazione}>
                  <Box>
                    <Button startIcon={<Code/>} variant={'outlined'} component={Link}
                            to={'/documentation'} target={'_blank'}>
                      documentazione
                    </Button>
                  </Box>
                  <Box>
                    <Button startIcon={<Visibility/>} variant={'outlined'}
                            onClick={handleMenu}>
                      altre Viste
                    </Button>
                  </Box>
                </Stack>
              </Stack>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <PagesButtons id={id} event={event?.data()}/>
              </Menu>
              <Grid container spacing={1}>
                {pinnedQueue.length > 0 && <Grid item xs={12}>
                  <Typography>
                    Domande fissate in home
                  </Typography>
                  <QueueCol queue={pinnedQueue}/>
                </Grid>}
                {pinnedQueue.length > 0 && <Grid item xs={12}>
                  <Divider/>
                </Grid>}
                <Grid item xs={12}>
                  <QueueCol queue={reorderedQueue}/>
                </Grid>
              </Grid>
            </Box>
          </ThemeProvider>}
      </Box>
    </Main>
  )
}

export const downloadReport = async (setLoading, eventId) => {
  setLoading(true);
  const response = await axios.get(`${API_URL}/report/${eventId}`);
  if (response) {
    const link = document.createElement('a');
    link.href = response.config.url; // Get url from response
    document.body.appendChild(link); // Required for Firefox  (?)
    link.click(); // Trigger the download
    document.body.removeChild(link); // Clean up
  }
  setLoading(false);
}